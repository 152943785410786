<template>
 <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
      :showAdditionalActions="true"
    >
      <template v-slot:criteriaRows>
        <v-row dense>
          <v-col cols="3">
            <FySelect id="gl-daily-criteria-FY-select"
              class="glDailyCriteriaFYSelect"
              :fyRange="fiscalYearRange"
              v-model="criteriaState.fiscalYear"
              @input="onChangeFy"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              class="gl-daily-criteria-display-section"
              id="glDailyCriteriaDisplaySection"
              v-model="criteriaState.dailySection"
              :items="glDailySections"
              label="Display Section"
              item-text="description"
              item-value="name"
              @change="searchByCriteria"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <ClarionDateControl
              v-model="criteriaState.businessDate"
              :isRequired="false"
              :label="`Business Date`"
              :isIsoDate="true"
              @blurred="onChangeBusinessDate"
              />
          </v-col>
          <v-col cols="3">
            <v-select
              class="gl-daily-criteria-fund"
              id="glDailyCriteriaFund"
              v-model="criteriaState.fund"
              :items="funds"
              label="By Fund"
              item-text="fundDesc"
              item-value="_id"
              @change="searchByCriteria"
            >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              class="gl-daily-criteria-bank"
              id="glDailyCriteriaBank"
              v-model="criteriaState.bank"
              label="By Bank"
              :items="banks"
              item-text="bankName"
              item-value="_id"
              @change="searchByCriteria"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:additionalActions>
        <v-col cols="2">
          <v-btn color="primary"
            rounded
            dense
            class= "tmc-additional-actions-collect-information"
            @click="collectInformation">
            <v-icon class="mr-2">{{ icons.mdiRhombusSplit }}</v-icon>
            Collect<br/>Info
          </v-btn>
      </v-col>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import {
  mdiRhombusSplit,
} from '@mdi/js';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import GLTransactionListMixin from './GLTransactionCommon.mixin';
import {
  calculateFiscalYears, toIsoDate,
} from '../../../util/shared/tmc-global';
import FySelect from '../../common/FySelect.vue';

const fundCrudKey = 'sharedFund';

export default {
  name: 'TransactionCriteria',
  mixins: [GLTransactionListMixin],
  components: {
    CRUDCriteria,
    ClarionDateControl,
    FySelect,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'transactionCriteria',
    },
  },
  data: () => ({
    loadFundsArr: [],
    icons: {
      mdiRhombusSplit,
    },
  }),
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.glDaily.glDailyTransaction.regYears || {};
        return calculateFiscalYears(registerdYears);
      },
      dailySectionTypes: (state) => state.enums.GlTransDailySectionEnum,
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    funds() {
      const fundObjs = this.items(fundCrudKey) || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    glDailySections() {
      if (!this.dailySectionTypes) {
        return [];
      }
      return [{ name: null, description: 'All' }, ...this.dailySectionTypes];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapMutations('glDaily/glDailyTransaction', [
      'setGLBalance',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('glDaily/glDailyTransaction', [
      'loadGLBalance',
      'glCollectInformation',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    onChangeFy() {
      this.searchByCriteria();
      this.loadGLBalanceByCriteria();
      this.loadFundList();
    },
    onChangeBusinessDate() {
      this.searchByCriteria();
      this.loadGLBalanceByCriteria();
    },
    async loadGLBalanceByCriteria() {
      this.loadGLBalance({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          businessDate: toIsoDate(this.criteriaState.businessDate),
        },
      });
    },
    handleLoadCRUDList() {
      this.$emit('loadCRUDList');
    },
    loadFundList() {
      this.loadFunds({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: fundCrudKey,
      });
    },
    async collectInformation() {
      /* Todo - add try-catch and loader */
      const criteria = {
        fiscalYear: this.criteriaState.fiscalYear,
        businessDate: this.criteriaState.businessDate,
      };
      await this.glCollectInformation({ criteria });
      this.searchByCriteria();
      this.loadGLBalanceByCriteria();
    },
  },
};
</script>
