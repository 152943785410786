<template>
  <div>
    <v-container>
      <div v-show="isVisibleProp"></div>
      <v-row>
        <v-col cols="3">
          <v-text-field id="glDailyTransItemFyText"
            class="gl-daily-trans-item-fy-text"
            label="Fiscal Year"
            v-model.number="editedItem.fiscalYear"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <ClarionDateControl
            :isIsoDate="true"
            label="Business Date"
            v-model="editedItem.businessDate"
            :isDisabled="isReadOnly"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editedItem.dailySection"
            :items="glDailySections"
            id="glDailyTransItemDailySectionSelect"
            class="gl-daily-trans-item-daily-section-select"
            label="Display Section"
            item-text="description"
            item-value="name"
            :readonly="isReadOnly"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.order"
            id="glDailyTransItemOrderNoText"
            class="gl-daily-trans-item-orderNo-text"
            label="Order No"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3"
          v-if="showFunds"
        >
          <v-select
            v-model="editedItem.fund"
            id="glDailyTransItemFundSelect"
            class="gl-daily-trans-item-fund-select"
            label="Fund"
            :items="funds"
            item-text="fundDesc"
            item-value="_id"
            :rules="selectingFundValidation"
            :readonly="isReadOnly"
            @change="checkBank"
          ></v-select>
        </v-col>
        <v-col cols="3"
          v-if="showBanks"
        >
          <v-select
            v-model="editedItem.bank"
            :items="banks"
            id="glDailyTransItemBankSelect"
            class="gl-daily-trans-item-bank-select"
            label="Bank"
            item-text="bankName"
            item-value="_id"
            :rules="selectingBankValidation"
            :readonly="isReadOnly"
            @change="checkFund"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.fromNumber"
            id="glDailyTransItemFromNoText"
            class="gl-daily-trans-item-fromNo-text"
            :readonly="isReadOnly"
            label="From No"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.toNumber"
            id="glDailyTransItemToNoText"
            class="gl-daily-trans-item-toNo-text"
            label="To No"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3"
          v-if="editedItem.dailySection !== 'PAYMENTS'"
        >
          <v-text-field
            v-model="editedItem.deposit"
            id="glDailyTransItemDepositText"
            class="gl-daily-trans-item-deposit-text"
            label="Deposit"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          v-if="editedItem.dailySection !== 'PAYMENTS'"
        >
          <v-text-field
            v-model="editedItem.transferIn"
            id="glDailyTransItemTransferInText"
            class="gl-daily-trans-transfer-in-text"
            label="Transfer In"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
        <v-col cols="3"
          v-if="editedItem.dailySection !== 'PAYMENTS'"
        >
          <v-text-field
            v-model="editedItem.transferOut"
            id="glDailyTransItemTransferOutText"
            class="gl-daily-trans-transfer-out-text"
            label="Transfer Out"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.payments"
            id="glDailyTransItemPaymentText"
            class="gl-daily-trans-item-payment-text"
            label="Payment"
            :readonly="isReadOnly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex align-end">
        <v-col cols="6">
          <v-textarea
            v-model="editedItem.description"
            id="glDailyTransItemDescriptionTextArea"
            class="gl-daily-trans-item-description-text-area"
            label="Description"
            rows="2"
            :readonly="isReadOnly"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import GLTransactionListMixin from './GLTransactionCommon.mixin';

const fundCrudKey = 'sharedFund';

export default {
  name: 'GlDailyEditedItem',
  mixins: [GLTransactionListMixin],
  components: {
    ClarionDateControl,
  },
  data: () => ({
    loadFundsList: [],
    fundsFromStore: [],
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'glReceipt-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
      dailySectionTypes: (state) => state.enums.GlTransDailySectionEnum,
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    isReadOnly() {
      return this.editedItem.isLocked;
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (isVisible) {
        this.$emit('activeItem', this.editedItem);
        if (this.criteriaOfFY) {
          this.loadFundsAPI();
        } else {
          this.getFundsFromStore();
        }
      }
      return [];
    },
    funds() {
      const fundList = this.criteriaOfFY ? this.loadFundsList : this.fundsFromStore;
      const updatedFundObj = fundList.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    criteriaOfFY() {
      return (this.criteria(this.baseCrudKey).fiscalYear === 0);
    },
    glDailySections() {
      if (!this.dailySectionTypes) {
        return [];
      }
      return this.dailySectionTypes;
    },
    showFunds() {
      return (this.editedItem.dailySection !== 'DEPOSITSANDCHECKS')
      || this.editedItem.dailySection === null;
    },
    showBanks() {
      return this.editedItem.dailySection !== 'COLLECTIONS'
      || this.editedItem.dailySection === null;
    },
    selectingFundValidation() {
      if (this.editedItem.bank !== null) {
        return [
          (value) => !value || 'Select either Bank/Fund!',
        ];
      }
      return [];
    },
    selectingBankValidation() {
      if (this.editedItem.fund !== null) {
        return [
          (value) => !value || 'Select either Bank/Fund!',
        ];
      }
      return [];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    async loadFundsAPI() {
      this.loadFundsList = await this.loadFunds({
        criteria: {
          fiscalYear: this.editedItem.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: fundCrudKey,
      });
      return this.loadFundsList;
    },
    getFundsFromStore() {
      this.fundsFromStore = this.items(fundCrudKey) || [];
      return this.fundsFromStore;
    },
    checkBank() {
      if (this.editedItem.bank !== null) {
        this.editedItem.bank = null;
      }
      return this.editedItem.bank;
    },
    checkFund() {
      if (this.editedItem.fund !== null) {
        this.editedItem.fund = null;
      }
      return this.editedItem.fund;
    },
  },
};
</script>
