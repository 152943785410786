var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TransactionList"},[_c('TransactionCriteria',{attrs:{"baseCrudKey":_vm.baseCrudKey},on:{"loadCRUDList":_vm.handleLoadCRUDList}}),_c('GLModesExpandableComponent',{attrs:{"baseCrudKey":_vm.baseCrudKey}}),_c('CRUDList',{attrs:{"canAdd":true,"canEdit":true,"canSave":_vm.saveable,"headers":_vm.headers,"showSelect":true,"showDate":true,"showTotal":true,"createNewItem":_vm.newItemDialog,"baseCrudKey":_vm.baseCrudKey,"selectedItems":_vm.selectedItems,"toolbarTitle":"General Ledger"},on:{"loadCRUDList":_vm.handleLoadCRUDList,"upsertItem":_vm.upsrtTransaction,"emitSelectedItem":_vm.emitSelectedItem},scopedSlots:_vm._u([{key:"editedItem",fn:function(ref){return [_c('TransactionEditedItem',{attrs:{"baseCrudKey":_vm.baseCrudKey},on:{"activeItem":_vm.activeItem}})]}},{key:"additionalToolsAtRight",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"glDaily-translist-lock-icon",attrs:{"id":"glDailyTranLisTLockBtn","color":"primary","fab":"","icon":"","small":""},on:{"click":_vm.lockRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiLockOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lock")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"glDaily-translist-unlock-icon",attrs:{"id":"glDailyTranListUnlockBtn","color":"primary","fab":"","icon":"","small":"","disabled":_vm.isUnlocked},on:{"click":_vm.unlockRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpenVariantOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Unlock")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.forceReloadDetails)?_c('ReportInfo',{attrs:{"closeDialogBox":_vm.closeDialogBox},on:{"settingDialogVal":_vm.settingDialogVal}}):_vm._e(),_c('v-btn',_vm._g(_vm._b({staticClass:"glDaily-translist-print-receipt-icon",attrs:{"id":"glDailyTranListPrintReceiptBtn","depressed":"","small":"","color":"primary"},on:{"click":_vm.openComponent}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]),_vm._v(" Print GL ")],1)]}}],null,true)},[_c('span',[_vm._v("Print GL")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"glDaily-translist-print-receipt-icon",attrs:{"id":"glDailyTranListPrintReceiptBtn","depressed":"","small":"","color":"primary"},on:{"click":_vm.openReport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]),_vm._v(" Print Daily ")],1)]}}],null,true)},[_c('span',[_vm._v("Print Daily")])])]}}],null,true)}),_c('div',{staticClass:"snackbar-gl-expandable-component"},[_c('v-snackbar',{attrs:{"color":"primary","timeout":-1},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('GLExpandableComponent',{attrs:{"baseCrudKey":_vm.baseCrudKey}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }