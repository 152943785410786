<template>
  <v-expansion-panels
    light
    accordion
    v-model="panel"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <!-- Text for Header -->
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="1" class="text-left">
            <b>BANKS</b>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="OD"
              readonly
              :value="amountFormat(totalBalanceByDailySections.banks.OD)"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="Deposits"
              readonly
              :value="amountFormat(totalBalanceByDailySections.banks.deposits)"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="Checks"
              readonly
              :value="amountFormat(totalBalanceByDailySections.banks.checks)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          dense
          class="d-flex align-end"
        >
          <v-col cols="1" class="text-left" align-self="center">
            <b>FUNDS</b>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="OD"
              readonly
              :value="amountFormat(totalBalanceByDailySections.funds.OD)"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="Collections"
              readonly
              :value="amountFormat(totalBalanceByDailySections.funds.collections)"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="Warrants"
              readonly
              :value="amountFormat(totalBalanceByDailySections.funds.warrants)"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-textarea
              label="Balance"
              readonly
              dense
              rows="2"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { calcTotalBalanceByDailySections } from './gl-balances.util';
import { amountFormat } from '../../../util/shared/vue-global';

export default {
  data: () => ({
    panel: -1,
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'GLExpandable',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapState({
      storeTotalBalance: (state) => state.glDaily.glDailyTransaction.totalBalance,
    }),
    totalBalanceByDailySections() {
      // console.info('--storeTotalBalance---', this.storeTotalBalance);
      const calculatedBalance = calcTotalBalanceByDailySections(this.storeTotalBalance);
      // console.info('--calculatedBalance---', calculatedBalance);
      return calculatedBalance;
    },
  },
  methods: {
    amountFormat,
  },
};
</script>
