<template>
  <v-container>
    <h3>General Ledger</h3>
    <TransactionList/>
  </v-container>
</template>

<script>

import TransactionList from '@/components/Gl/Transaction/TransactionList.vue';

export default {
  name: 'GeneralLedger',
  components: {
    TransactionList,
  },
};
</script>
