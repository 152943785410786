/*
  This mixin is very specific for gl program
  It is a kind of common-mixin extended file containing common/repeated code
  Be careful before mixin it anywhere else or defining directly in this file
*/
import {
  mapGetters,
} from 'vuex';

const bankCrudKey = 'sharedBank';

export default {
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    banks() {
      const bankObjs = this.items(bankCrudKey) || [];
      return [{ _id: null, bankName: '' }, ...bankObjs];
    },
  },
};
