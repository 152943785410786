<template>
  <div
    class="TransactionList">
    <TransactionCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <GLModesExpandableComponent
      :baseCrudKey="baseCrudKey"
    />
    <CRUDList
      :canAdd="true"
      :canEdit="true"
      :canSave="saveable"
      :headers="headers"
      :showSelect="true"
      :showDate="true"
      :showTotal="true"
      :createNewItem="newItemDialog"
      :baseCrudKey="baseCrudKey"
      :selectedItems="selectedItems"
      toolbarTitle="General Ledger"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtTransaction"
      @emitSelectedItem="emitSelectedItem"
    >
      <template v-slot:[`editedItem`]="{}">
        <TransactionEditedItem
          :baseCrudKey="baseCrudKey"
          @activeItem="activeItem"
        />
      </template>
      <template v-slot:[`additionalToolsAtRight`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="glDailyTranLisTLockBtn"
              class="glDaily-translist-lock-icon"
              color="primary"
              v-bind="attrs"
              v-on="on"
              fab icon small
              @click="lockRecords"
            >
              <v-icon>
                {{ icons.mdiLockOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Lock</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              id="glDailyTranListUnlockBtn"
              class="glDaily-translist-unlock-icon"
              color="primary"
              fab icon small
              :disabled="isUnlocked"
              @click="unlockRecords"
            >
              <v-icon>
                {{ icons.mdiLockOpenVariantOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Unlock</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <ReportInfo
              v-if="forceReloadDetails"
              :closeDialogBox="closeDialogBox"
              @settingDialogVal="settingDialogVal"
            />
          <v-btn
            id="glDailyTranListPrintReceiptBtn"
            class="glDaily-translist-print-receipt-icon"
            depressed
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openComponent"
          >
          <v-icon left>
            {{ icons.mdiPrinter }}
          </v-icon>
          Print GL
          </v-btn>
          </template>
            <span>Print GL</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            id="glDailyTranListPrintReceiptBtn"
            class="glDaily-translist-print-receipt-icon"
            depressed
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openReport"
          >
          <v-icon left>
            {{ icons.mdiPrinter }}
          </v-icon>
          Print Daily
          </v-btn>
          </template>
            <span>Print Daily</span>
        </v-tooltip>
      </template>
    </CRUDList>
    <div class="snackbar-gl-expandable-component">
      <v-snackbar
        v-model="snackbar"
        color="primary"
        :timeout="-1"
      >
        <GLExpandableComponent
          :baseCrudKey="baseCrudKey"
        />
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
import {
  mdiPrinter,
  mdiLockOutline,
  mdiLockOpenVariantOutline,
} from '@mdi/js';
import TransactionCriteria from './TransactionCriteria.vue';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import TransactionEditedItem from './TransactionEditedItem.vue';
import { amountToNumber, extractId } from '../../../util/shared/vue-global';
import { updateDateFormat, toFiscalYear, toIsoDate } from '../../../util/shared/tmc-global';
import { getRawJwt } from '../../../util/jwt';
import { queryStringCalcForDailyGL } from './report-util';
import { clients } from '../../../util/clients';
import { glLockUnlockOperations } from './gl-transaction.util';
import ReportInfo from './ReportInfo.vue';
import GLExpandableComponent from './GLExpandableComponent.vue';
import GLModesExpandableComponent from './GLModesExpandableComponent.vue';

const { backendRest } = clients.direct;

const getHeaders = () => {
  const headers = [
    // { text: 'Key', value: '' },
    // { text: 'Line', value: '' },
    { text: 'Fund', value: 'fund.fund' },
    { text: 'Bank', value: 'bank.altKeyBase' },
    { text: 'Description', value: 'description' },
    { text: 'Sec', value: 'dailySection', formatter: 'getFirstChar' },
    { text: 'From', value: 'fromNumber' },
    { text: 'To', value: 'toNumber' },
    {
      text: 'Deposit', value: 'deposit', formatter: 'amount', align: 'right',
    },
    {
      text: 'Transfer In', value: 'transferIn', formatter: 'amount', align: 'right',
    },
    {
      text: 'Transfer Out', value: 'transferOut', formatter: 'amount', align: 'right',
    },
    {
      text: 'Payments', value: 'payments', formatter: 'amount', align: 'right',
    },
    // { text: 'J/T', value: '' }, => this isn't in our DB?
    { text: 'Locked', value: 'isLocked', formatter: 'boolToStr' },
  ];
  return headers;
};

export default {
  data: () => ({
    baseCrudKey: 'glDailyTransaction',
    headers: [],
    dateForCriteria: ['businessDate'],
    closeDialogBox: false,
    forceReloadDetails: false,
    icons: {
      mdiPrinter,
      mdiLockOutline,
      mdiLockOpenVariantOutline,
    },
    snackbar: true,
    saveable: true,
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadLists();
  },
  components: {
    CRUDList,
    TransactionCriteria,
    TransactionEditedItem,
    ReportInfo,
    GLExpandableComponent,
    GLModesExpandableComponent,
  },
  computed: {
    ...mapGetters(['todaysDate']),
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    ...mapState({
      storedSelectedItems: (state) => state.glDaily.glDailyTransaction.selectedItems,
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
    queryString() {
      return queryStringCalcForDailyGL({
        criteria: this.criteriaState,
        token: this.getAuthToken,
      });
    },
    getAuthToken() {
      return getRawJwt();
    },
    isUnlocked() {
      for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
        if (this.selectedItems[i].isLocked) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions(['flashInfo', 'flashSuccess']),
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('glDaily/glDailyTransaction', [
      'loadGlDailyTransaction',
      'upsertGlDailyTrans',
      'loadFiscalRegYears',
      'loadGLBalance',
      'glLockUnlockTransaction',
    ]),
    ...mapMutations('glDaily/glDailyTransaction', [
      'setSelectedItems',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.updateCriteriaForReq();
      this.loadGlDailyTransaction({ criteria, baseCrudKey }).then(() => {
        this.selectedItems = [];
      });
    },
    updateCriteriaForReq() {
      const updatedCriteria = updateDateFormat(this.criteriaState, this.dateForCriteria);
      return updatedCriteria;
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      // need to refactor this
      updatedItem.order = amountToNumber(updatedItem.order);
      updatedItem.fromNumber = amountToNumber(updatedItem.fromNumber);
      updatedItem.toNumber = amountToNumber(updatedItem.toNumber);
      updatedItem.deposit = amountToNumber(updatedItem.deposit);
      updatedItem.transferIn = amountToNumber(updatedItem.transferIn);
      updatedItem.transferOut = amountToNumber(updatedItem.transferOut);
      updatedItem.payments = amountToNumber(updatedItem.payments);
      if (updatedItem.dailySection === 'DEPOSITSANDCHECKS') {
        updatedItem.fund = null;
      }
      if (updatedItem.dailySection === 'COLLECTIONS') {
        updatedItem.bank = null;
      }
      if (updatedItem.dailySection === 'PAYMENTS') {
        updatedItem.deposit = null;
        updatedItem.transferIn = null;
        updatedItem.transferOut = null;
      }
      if (updatedItem.bank) {
        updatedItem.bank = extractId(updatedItem.bank);
      }
      if (updatedItem.fund) {
        updatedItem.fund = extractId(updatedItem.fund);
      }
      const response = await this.upsertGlDailyTrans({ item: updatedItem, baseCrudKey });
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.loadGLBalanceList();
      return response;
    },
    async loadBankList() {
      const bankList = await this.loadBanks({
        criteria: {},
        baseCrudKey: 'sharedBank',
      });
      return bankList;
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        businessDate: this.todaysDate,
        fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        dailySection: null,
        bank: null,
        fund: null,
      };
      if (this.criteriaState.dailySection !== null
      && this.criteriaState.dailySection !== undefined) {
        newItem.dailySection = this.criteriaState.dailySection;
      }
      return newItem;
    },
    loadFundsList() {
      this.loadFunds({
        criteria: {
          fiscalYear: toFiscalYear(new Date(this.todaysDate)),
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedFund',
      });
    },
    initCriteria() {
      this.criteriaState = {};
      this.criteriaState.businessDate = this.todaysDate;
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    loadGLBalanceList() {
      this.loadGLBalance({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          businessDate: toIsoDate(this.criteriaState.businessDate),
        },
      });
    },
    loadLists() {
      this.loadBankList();
      this.loadFiscalRegYears();
      this.loadFundsList();
      this.loadGLBalanceList();
    },
    openComponent() {
      this.closeDialogBox = true;
      this.forceReloadDetails = true;
    },
    settingDialogVal(value) {
      this.closeDialogBox = value;
      this.forceReloadDetails = false;
    },
    openReport() {
      const toUrl = `${backendRest.defaults.baseURL}/gl/transaction/dailyGL${this.queryString}`;
      window.open(toUrl);
    },
    selectedItemsIDs() {
      const arrayOfIds = [];
      for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
        arrayOfIds.push(this.selectedItems[i]._id);
      }
      return arrayOfIds;
    },
    async lockRecords() {
      if ((this.selectedItems || []).length === 0) {
        if (window.confirm(`This will lock all the record/s for the date ${this.criteriaState.businessDate}!`)) {
          await this.lockByDateAndFy(
            this.criteriaState.fiscalYear,
            this.criteriaState.businessDate,
          );
          this.handleLoadCRUDList();
          this.flashSuccess('All the records are now successfully locked!');
        }
      } else if (window.confirm(`This will lock the ${(this.selectedItems || []).length} selected record/s!`)) {
        await this.lockUnlockByIds(glLockUnlockOperations().lockByIds);
        this.handleLoadCRUDList();
        this.flashSuccess('All the records are now successfully locked!');
      } else {
        this.selectedItems = [];
      }
    },

    async lockByDateAndFy(fiscalYear, businessDate) {
      await this.glLockUnlockTransaction({
        criteria: {
          fiscalYear,
          businessDate: toIsoDate(businessDate),
          operation: glLockUnlockOperations().lockByDateAndFy,
        },
      });
    },

    async lockUnlockByIds(operation) {
      const selectedIds = this.selectedItemsIDs();
      await this.glLockUnlockTransaction({
        criteria: {
          _ids: selectedIds || [],
          operation,
        },
      });
    },

    async unlockRecords() {
      if (window.confirm(`This will unlock the ${(this.selectedItems || []).length} selected record/s!`)) {
        await this.lockUnlockByIds(glLockUnlockOperations().unlockByIds);
        this.handleLoadCRUDList();
        this.flashSuccess('All the records are now successfully unlocked!');
      } else {
        this.selectedItems = [];
      }
    },
    activeItem(item) {
      if (item.isLocked === true) {
        this.saveable = false;
        return this.saveable;
      }
      this.saveable = true;
      return this.saveable;
    },
  },
};
</script>

<style lang="sass">
.snackbar-gl-expandable-component
  .v-snack__wrapper
    max-width: 100%
    width: 100%
  .v-snack__content
    padding: 0 0 0 0
  .v-expansion-panel-header
    min-height: 0

</style>
