<template>
<div class="elevation-3 ma-3 pa-3 gl-modes-expandable-data">
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b>Total</b>
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="2" class="text-left">
              <b>BANKS</b>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Deposit"
                readonly
                :value="amountFormat(totalBalanceByModes.bank.deposit)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Transfer In"
                readonly
                :value="amountFormat(totalBalanceByModes.bank.transferIn)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Transfer Out"
                readonly
                :value="amountFormat(totalBalanceByModes.bank.transferOut)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Payments"
                readonly
                :value="amountFormat(totalBalanceByModes.bank.payment)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="text-left">
              <b>FUNDS</b>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Deposit"
                readonly
                :value="amountFormat(totalBalanceByModes.fund.deposit)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Transfer In"
                readonly
                :value="amountFormat(totalBalanceByModes.fund.transferIn)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Transfer Out"
                readonly
                :value="amountFormat(totalBalanceByModes.fund.transferOut)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Payments"
                readonly
                :value="amountFormat(totalBalanceByModes.fund.payment)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</div>
</template>
<script>
import {
  mapState,
} from 'vuex';
import { calTotalModesBalance } from './gl-balances.util';
import { amountFormat } from '../../../util/shared/vue-global';

export default {
  data: () => ({
  }),
  created() {
  },
  // this isn't required now but might be useful in future
  // if we don't get values dynamically from state
  // watch: {
  //   storeTotalBalance: {
  //     handler: 'totalBalanceByModes',
  //     deep: true,
  //   },
  // },
  computed: {
    ...mapState({
      storeTotalBalance: (state) => state.glDaily.glDailyTransaction.totalBalance,
    }),
    totalBalanceByModes() {
      const calculatedModesBalance = calTotalModesBalance(
        this.storeTotalBalance,
      );
      return calculatedModesBalance;
    },
  },
  methods: {
    amountFormat,
  },
};
</script>
