const isDisplaySectionOD = (record) => record._dailySection === 'OFFICIALDEPOSITORY';
const isDisplaySectionCollections = (record) => record._dailySection === 'COLLECTIONS';
const isDisplaySectionPayments = (record) => record._dailySection === 'PAYMENTS';
const isDisplaySectionDepositsOrChecks = (record) => record._dailySection === 'DEPOSITSANDCHECKS';

export const calcTotalBalanceByDailySections = (records) => {
  const totalBalance = {
    funds: {
      OD: 0.0,
      collections: 0.0,
      warrants: 0.0,
    },
    banks: {
      OD: 0.0,
      deposits: 0.0,
      checks: 0.0,
    },
  };
  for (const record of records) {
    /* Will update - currently we don't have fund data */
    if (!record._fund) {
      if (isDisplaySectionOD(record)) {
        totalBalance.funds.OD += (record.totalDeposit + record.totalTransferIn)
          - (record.totalTransferOut + record.totalPayments);
      }
      if (isDisplaySectionCollections(record)) {
        totalBalance.funds.collections += (record.totalDeposit + record.totalTransferIn)
          - (record.totalTransferOut + record.totalPayments);
      }
      if (isDisplaySectionPayments(record)) {
        totalBalance.funds.warrants += record.totalPayments;
      }
    }
    /* Will update - currently we don't have bank data */
    if (!record._bank) {
      if (isDisplaySectionOD(record)) {
        totalBalance.banks.OD += (record.totalDeposit + record.totalTransferIn)
          - (record.totalTransferOut + record.totalPayments);
      }
      if (isDisplaySectionDepositsOrChecks(record)) {
        totalBalance.banks.deposits += record.totalDeposit;
        totalBalance.banks.checks += record.totalPayments;
      }
    }
  }
  return totalBalance;
};

export const calTotalModesBalance = (balanceArr) => {
  const bankFundTotalModeWise = {
    fund: {
      deposit: 0.0,
      transferIn: 0.0,
      payment: 0.0,
      transferOut: 0.0,
    },
    bank: {
      deposit: 0.0,
      transferIn: 0.0,
      payment: 0.0,
      transferOut: 0.0,
    },
  };
  for (const balance of balanceArr) {
    if (balance._fund) {
      bankFundTotalModeWise.fund.deposit += balance.totalDeposit;
      bankFundTotalModeWise.fund.transferIn += balance.totalTransferIn;
      bankFundTotalModeWise.fund.payment += balance.totalPayments;
      bankFundTotalModeWise.fund.transferOut += balance.totalTransferOut;
    }
    if (balance._bank) {
      bankFundTotalModeWise.bank.deposit += balance.totalDeposit;
      bankFundTotalModeWise.bank.transferIn += balance.totalTransferIn;
      bankFundTotalModeWise.bank.payment += balance.totalPayments;
      bankFundTotalModeWise.bank.transferOut += balance.totalTransferOut;
    }
  }
  return bankFundTotalModeWise;
};

export default {};
