<template>
  <div>
    <v-container>
      <v-dialog
        v-model="isDialog"
        max-width="60vw"
      >
        <v-card
          v-show="true"
        >
          <v-alert type="info" outlined>
            <v-card-title>
              <span v-html="dialogHeader"></span>
            </v-card-title>
            <div>
              <v-card-text>
                <v-form ref="form"
                  class="my-form"
                >
                  <v-container class="grey lighten-5">
                    <v-row align="center"
                    >
                      <v-col cols="3">
                        <v-select
                          class="reportInfoDialogFiscalYear"
                          id="report-info-dialog-fiscalYear"
                          v-model="criteria.fiscalYear"
                          :items="fiscalYearList"
                          item-text="name"
                          item-value="year"
                          label="By Fiscal Year"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <ClarionDateControl
                          :isIsoDate="true"
                          label="From Date"
                          v-model="criteria.fromDate"
                        />
                      </v-col>
                      <v-col cols="3">
                        <ClarionDateControl
                          :isIsoDate="true"
                          label="To Date"
                          v-model="criteria.toDate"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3"
                      >
                        <v-checkbox
                          v-model="criteria.isPrintSubledgers"
                          class="reportInfoDialogPrintSubledgers"
                          id="report-info-dialog-print-subledgers"
                          label="Print Subledgers"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3"
                      >
                        <v-checkbox
                          v-model="criteria.isIncludeApportionment"
                          class="reportInfoDialogIncludeApportionment"
                          id="report-info-dialog-include-apportionment"
                          label="Include Apportionment"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3"
                      >
                        <v-checkbox
                          v-model="criteria.isPrintTotals"
                          class="reportInfoDialogPrintTotals"
                          id="report-info-dialog-print-totals"
                          label="Print Totals"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3"
                      >
                        <v-checkbox
                          v-model="criteria.isPrintAllLines"
                          class="reportInfoDialogPrintAllLines"
                          id="report-info-dialog-print-all-lines"
                          label="Print All Lines"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4"
                      >
                        <v-text-field
                          v-model="criteria.singleLineFundWithDetail"
                          label="Print Single Line Fund(Detail)"
                          class="reportInfoDialogSingleLineFundWithDetail"
                          id="report-info-dialog-single-line-fund-with-detail"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4"
                      >
                        <v-text-field
                          v-model="criteria.singleLineBankWithDetail"
                          label="Print Single Line Bank(Detail)"
                          class="reportInfoDialogSingleLineBankWithDetail"
                          id="report-info-dialog-single-line-bank-with-detail"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="printReport"
                >
                Print
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                Cancel
                </v-btn>
              </v-card-text>
            </div>
          </v-alert>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import { clients } from '../../../util/clients';
import { queryStringCalc } from './report-util';
import { getRawJwt } from '../../../util/jwt';
import { getFYList, toShortDate, getMaxFY } from '../../../util/shared/tmc-global';

const { backendRest } = clients.direct;

export default {
  name: 'ReportInfo',
  components: {
    ClarionDateControl,
  },
  props: {
    closeDialogBox: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogHeader: 'Report Info',
    criteria: {
      fiscalYear: null,
      fromDate: '',
      toDate: '',
      isPrintSubledgers: false,
      isIncludeApportionment: false,
      isPrintTotals: true,
      singleLineFundWithDetail: '',
      isPrintAllLines: false,
      singleLineBankWithDetail: '',
    },
  }),
  created() {
    this.initializeFiscalYears();
    this.initCriteria();
  },
  computed: {
    ...mapState({
      regYears: (state) => state.glDaily.glDailyTransaction.regYears,
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    isDialog: {
      get() {
        return this.closeDialogBox;
      },
      set(value) {
        this.$emit('settingDialogVal', value);
      },
    },
    queryString() {
      return queryStringCalc({
        criteria: this.criteria,
        token: this.getAuthToken,
      });
    },
    getAuthToken() {
      return getRawJwt();
    },
    fiscalYearList() {
      const registerdYears = (this.regYears || {});
      const fyList = getFYList(registerdYears);
      return fyList;
    },
  },
  methods: {
    close() {
      this.$emit('settingDialogVal', false);
    },
    async printReport() {
      const toUrl = `${backendRest.defaults.baseURL}/gl/transaction/printGL${this.queryString}`;
      window.open(toUrl);
    },
    initializeFiscalYears() {
      const maxFY = getMaxFY(this.fiscalYearList || []);
      this.criteria.fiscalYear = maxFY;
    },
    initCriteria() {
      this.criteria.fromDate = toShortDate(this.todaysDate);
      this.criteria.toDate = toShortDate(this.todaysDate);
    },
  },
};
</script>

<style>
.my-form {
  border: 0.5px groove;
}
</style>
