import { generateQueryString } from '../../common/base/report/report-util';

export const queryStringCalc = ({
  criteria,
  token,
}) => {
  const params = [];
  if (criteria.fiscalYear) {
    params.push(`fiscalYear=${criteria.fiscalYear}`);
  }

  if (criteria.fromDate) {
    params.push(`fromDate=${criteria.fromDate}`);
  }

  if (criteria.toDate) {
    params.push(`toDate=${criteria.toDate}`);
  }

  if (criteria.isPrintSubledgers) {
    params.push(`isPrintSubledgers=${criteria.isPrintSubledgers}`);
  }

  if (criteria.isIncludeApportionment) {
    params.push(`isIncludeApportionment=${criteria.isIncludeApportionment}`);
  }

  if (criteria.isPrintTotals) {
    params.push(`isPrintTotals=${criteria.isPrintTotals}`);
  }

  if (criteria.isPrintAllLines) {
    params.push(`isPrintAllLines=${criteria.isPrintAllLines}`);
  }

  if (criteria.singleLineFundWithDetail) {
    params.push(`singleLineFundWithDetail=${criteria.singleLineFundWithDetail}`);
  }
  if (criteria.singleLineBankWithDetail) {
    params.push(`singleLineBankWithDetail=${criteria.singleLineBankWithDetail}`);
  }

  if (token) {
    params.push(`token=${token}`);
  }
  return generateQueryString(params);
};

export const queryStringCalcForDailyGL = ({
  criteria,
  token,
}) => {
  const params = [];
  if (criteria.fiscalYear) {
    params.push(`fiscalYear=${criteria.fiscalYear}`);
  }

  if (criteria.businessDate) {
    params.push(`businessDate=${criteria.businessDate}`);
  }

  if (token) {
    params.push(`token=${token}`);
  }
  return generateQueryString(params);
};

export default {
  queryStringCalc,
};
